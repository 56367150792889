.label {
  font-size: 18px;
  margin-bottom: 20px;
  color: black;
  font-style: unset;
}
.text {
  font-size: 16px;
  color: black;
}
