//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin-bottom: 8px;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

.card-padding {
  background-color: "white";
  border-color: "white";
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    );
  background-clip: content-box, padding-box;
}
.card-body-scroll {
  width: 100%;
  max-width: 90vw;
  overflow-y: auto;
}
.card-body-mobile {
  @media (max-width: $mobileScreens) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px !important;
  }
}
.btn-card-body {
  @media (max-width: $mobileScreens) {
    margin-top: 10px;
  }
}
.box-search-number {
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    margin-bottom: 0;
  }
  @media (max-width: $mobileScreens) {
    flex-direction: column;
  }
}
