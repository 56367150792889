.container-filter-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 8px;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    align-items: start;
  }
}
.label-filter-data {
  width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
}
.form-control {
  margin-right: 0 !important;
  height: 36px !important;
  margin-left: 10px;
  @media (max-width: $mobileScreens) {
    margin-left: 0px;
  }
}
.react-datepicker__input-container {
  width: 120px;
  @media (max-width: $mobileScreens) {
    width: 100%;
  }
}
.react-datepicker-wrapper {
  width: 120px !important;
  box-sizing: border-box;
}
.filter-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  @media (max-width: $mobileScreens) {
    width: 100%;
    flex-direction: column;
    align-items: start;
    margin-left: 0 !important;
  }
}
.btn-clear-filter {
  width: 150px;
  @media (max-width: $mobileScreens) {
    margin-top: 20px;
  }
}
.box-checkbox-only-paid {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  text-align: center;
  @media (max-width: $mobileScreens) {
    margin-top: 20px;
    margin-left: 0px;
  }
}
label {
  margin: 0;
}

.date-picket-input {
  width: 120px;
  @media (max-width: $mobileScreens) {
    width: 100%;
    margin-left: 0px;
  }
}
table {
  width: 100%;
  max-width: 100vw;
  overflow-y: auto;
}

.custom-select {
  width: 20%;

  @media (max-width: $mobileScreens) {
    width: 100%;
    .form-control {
      margin-left: 0px;
    }
  }
  input {
    margin-left: 0px;
  }
}

.name-custom-select {
  margin-left: 10px;
  @media (max-width: $mobileScreens) {
    margin-left: 0px;
    .form-control {
      margin-left: 0px;
    }
  }
}
