.list {
  &-title {
    display: flex;
  }
  &-btn {
    padding: 0 0 20px 0;
    display: flex;
    justify-content: right;
  }
  &-item {
    display: flex;
    align-items: center;
    margin: 20px 0 0 0;
  }
  &-item__param {
    width: 25%;
  }
  &-title__item {
    width: 25%;
    font-size: 24px;
    display: flex;
    align-items: center;

    span {
      position: relative;
      height: 36px;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 36px;
        right: -30px;
        bottom: 0px;
      }
    }
  }
}

.popover-footer {
  padding: 10px 0 10px 0;
}
