.file-input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  margin-right: 10px;
  width: 120px;
  min-height: 120px;
  cursor: pointer;
}

.mainPhoto-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 120px;
}

.mainPhoto-border {
  border: 1px solid #000;
}

.additionally-photos {
  display: flex;
  flex-direction: column;
}

.additionally-photo {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.btn-delete {
  max-height: 30px;
  border: 1px solid black;
  margin-left: 20px;
}
