.header-payments-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  align-items: center;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    width: 100%;
    gap: 10px;
    align-items: start;
  }
}
.card-payments-page {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
}
.filter-date-payments-page {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  width: 250px;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    width: 100%;
    gap: 10px;
    text-align: start;
  }
}

.list-btn-message-page {
  display: flex;
  justify-content: start !important;
  gap: 20px;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    width: 100%;
    gap: 10px;
    text-align: start;
  }
}
.po {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
  @media (max-width: $mobileScreens) {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    align-items: start;
    justify-content: start;
  }
}
p.no-message {
  align-items: center;
  width: 100%;
}
.tr-no-message {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
}
