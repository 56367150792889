.Modal {
  position: fixed;
  z-index: 1003;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: none;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.511);

  &_open {
    display: flex;
  }

  &__body {
    min-width: 400px;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__header__title {
    width: 100%;
    display: flex;
    justify-content: center;

    h3 {
      margin: 0;
      margin-left: 20px;
    }
  }

  &__close {
    margin-left: 20px;
    font-size: 18px;
    cursor: pointer;
  }
}
