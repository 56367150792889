.box-zoom-photo-modal {
  position: fixed;
  background-color: #0000004b;
  top: 0;
  z-index: 8;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10;
}
.box-zoom-photo-active {
  position: fixed;
  width: 100px;
  height: 70px;
  top: 90px;
  right: 50px;
  display: flex;
  justify-content: end;
  box-sizing: border-box;
  padding-top: 20px;
  padding-right: 100px;
}
.button-zoom-modal {
  background-color: #38a4f8;
  width: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 30px;
  // margin-top: 90px;
}
.button-arrow {
  background-color: #38a4f8;
  border-radius: 5px;
  border: 0;
  width: 30px;
  height: 30px;
}
.title-photo {
  font-size: 20px;
  background-color: #38a4f8;
  font-family: "Roboto";
  text-align: center;
  color: white;
  border-radius: 5px;
}
.center-box-zoom-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
}
.photo-box-zoom-modal {
  display: flex;
  flex-direction: column;
  height: 700px;
  justify-content: center;
}
