.header-card-page {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: white;
}
.card-cars-page {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
}
.filters-cars-page {
  @media (max-width: $mobileScreens) {
    display: flex;
    flex-direction: column;

    gap: 10px;
  }
}
// .card-buttons {
//   @media (max-width: $mobileScreens) {
//     margin-top: 20px;
//   }
// }
.btn-header-pages {
  @media (max-width: $mobileScreens) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
