.table-rents {
  width: 100%;
  border: none;
  margin-bottom: 20px;
  border-collapse: separate;
}
.table-rents thead th {
  font-weight: bold;
  text-align: left;
  border: none;
  padding: 10px 15px;
  background: #ededed;
  font-size: 14px;
  border-top: 1px solid #ddd;
  position: sticky;
  top: 0;
}
.table-rents tr th:first-child,
.table-rents tr td:first-child {
  border-left: 1px solid #ddd;
}
.table-rents tr th:last-child,
.table-rents tr td:last-child {
  border-right: 1px solid #ddd;
}
.table-rents thead tr th:first-child {
  border-radius: 20px 0 0 0;
}
.table-rents thead tr th:last-child {
  border-radius: 0 20px 0 0;
}
.table-rents tbody td {
  text-align: left;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  vertical-align: top;
}
.table-rents tbody tr:nth-child(even) {
  background: #f8f8f8;
}
.table-rents tbody tr:last-child td {
  border-bottom: 1px solid #ddd;
}
.table-rents tbody tr:last-child td:first-child {
  border-radius: 0 0 0 20px;
}
.table-rents tbody tr:last-child td:last-child {
  border-radius: 0 0 20px 0;
}
