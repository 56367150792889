.user {
  &__photo {
    margin-top: 16px;
    align-items: center;
    text-align: center;
    &_empty {
      //   width: 60%;
      //   height: 500px;
      //   border: 1px solid #000;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
    }
    img {
      width: 100%;
      height: 300px;
      object-fit: contain;
    }
  }
  &__item {
    margin: 0 0 10px 0;
  }
}
h3 {
  font-size: 12px;
  margin-top: 12px;
}
.box-user-information {
  margin-top: 20px;
  margin-bottom: 20px;
}
