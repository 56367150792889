.chats-mobile {
  width: 100%;
  margin-right: 20px;
  max-height: 80vh;
  overflow: auto;
  margin-right: 2px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 3px;
  box-sizing: border-box;
}
.text-name {
  font-size: 12px !important;
}
.car-button {
  font-size: 30px;
  cursor: pointer;
}
