.filter-cars {
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    width: 100%;
    gap: 10px;
    align-items: start;
  }
}
.btn-cars-page {
  @media (max-width: $mobileScreens) {
    width: 100%;
    margin-top: 10px;
  }
}
.box-btn-cars-page {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.box-btn-create-cars-page {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10px;
  @media (max-width: $mobileScreens) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.box-btn-car-user {
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
  }
}
