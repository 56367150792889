.header-tours-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
}
.card-tours-page {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
}
.filter-date-tours-page {
  display: flex;
  flex-direction: row;
  text-align: center;
}
