.header-clients-page {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 20px;
  background-color: white;
  margin-left: 30px;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    width: 100%;
    gap: 20px;
    text-align: start;
    margin-left: 0;
    align-items: start;
  }
}
.card-clients-page {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
}
.filter-date-clients-page {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: start !important;
  margin-left: 5px;
}
.react-kanban-board {
  height: 100%;
  overflow-y: auto !important;
  flex-direction: column !important;
  > div {
    flex-grow: 1;
    display: flex;
    > div {
      height: auto !important;
      > div:nth-child(2) {
        height: 98% !important;
      }
    }
  }
}
.header-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid black;
}
.time-label {
  font-size: 10px;
  text-align: center;
}
.name-label {
  font-size: 14px;
  font-weight: bold;
}
.btn-cliens-pages {
  @media (max-width: $mobileScreens) {
    width: 100%;
  }
}
