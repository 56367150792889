.header-calendar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    align-items: start;
  }
}
.box-select-avto {
  width: 20%;
  @media (max-width: $mobileScreens) {
    width: 100%;
  }
}
.box-change-date {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  margin-right: 20px;
  height: 46px;
  @media (max-width: $mobileScreens) {
    // margin-right: 0px;
    padding-right: 10px;
    width: 100%;
  }
}
.label-change-date {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  p {
    margin: 0;
    padding: 0;
  }
}
.box-checkbox-only-actual {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  text-align: center;
  @media (max-width: $mobileScreens) {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.filter {
  background-color: red !important;
  color: red !important;
}

.table-fixed-head {
  table-layout: fixed;
  border-collapse: collapse;
  height: 70vh;
  width: auto;
  position: sticky;
  top: 0;
  margin-bottom: 30px;
}

.table-fixed-head thead th {
  // padding: 5px;
  // text-align: center;
  // width: 33.333vw;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
}

.table-fixed-head tr th {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 3;
}

.table-fixed-head tbody {
  height: calc(100vh+30px);
}
