//
// _progress.scss
//

// Progress height small
.progress-sm {
  height: 5px;
}

// Progress height medium
.progress-md {
  height: 8px;
}

// Progress height large
.progress-lg {
  height: 12px;
}

.custom-progess {
  position: relative;
  .progress-icon {
    position: absolute;
    top: -12px;
    .avatar-title {
      background: $card-bg;
    }
  }
}

.box-tail-spin {
  // position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  // min-height: 100vh;
  z-index: 9999;
}
