.photo-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    margin-top: 12px;
  }
}
.header {
  font-size: 18px;
  padding-top: 20px;
  padding-left: 25px;
}
.card-rent-page {
  margin-top: 20px;
}
.button-rent-page {
  margin-right: 10px;
}
