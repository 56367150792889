//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
  }
}

.btn-pagination {
  @media (max-width: $mobileScreens) {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.pagination-box {
  gap: 10px;
  @media (max-width: $mobileScreens) {
    width: 100%;
    justify-content: space-between;
  }
}
