.header-fines-page {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
}
.card-fines-page {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
}
.text-onclick {
  cursor: pointer;
  text-decoration: underline;
}
.box-input {
  width: 20%;
  display: flex;
  align-items: center;
  @media (max-width: $mobileScreens) {
    width: 100%;
    .form-control {
      margin-left: 0px;
    }
  }
}
