.header-rent-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
  @media (max-width: $mobileScreens) {
    flex-direction: column;
    gap: 10px;
  }
}
.card-user-page {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
}
